import TagManager from 'react-gtm-module';
import { DataLayerGaEvent } from '@/lib/types';

export const initializeTagManger = (tagManagerId: string) => {
  TagManager.initialize({
    gtmId: tagManagerId,
  });
};

export const pushToDataLayer = (event: DataLayerGaEvent) => {
  /** @notice Reset "dataLayer", events data get mixed otherwise */
  TagManager.dataLayer({
    dataLayer: {
      env: null,
      user: null,
      content: null,
    },
  });
  TagManager.dataLayer({ dataLayer: event });
};

import MobileDetect from 'mobile-detect';
import { PlatformType } from '@/lib/enums/platformType.enum';

export const getPlatform = (): PlatformType => {
  const md = new MobileDetect(window.navigator.userAgent);
  if (!!md.phone() || !!md.mobile()) {
    return PlatformType.mobile;
  }
  return PlatformType.desktop;
};

'use client';

import { DateTime } from 'luxon';
import { useContext } from 'react';
import useLocale from '@/lib/hooks/useLocale';
import { getPlatform } from '@/lib/helpers/analytics';
import { GaEventBody, GaEventBodyBase, GaEventEnv, GaEventUser, GaLeadEventBody } from '@/lib/types';
import { getChannelId } from '@/lib/getChannelId';
import { AuthContext } from '@/components/auth/AuthProvider';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { GaEvent } from '@/lib/enums/gaEvent.enum';
import { pushToDataLayer } from '@/lib/helpers/tagManager';

type GaEventProps = {
  event: GaEvent;
  content?: GaEventBody;
};

const useGaEvents = () => {
  const { user } = useContext(AuthContext);
  const locale = useLocale();

  const eventEnv = (): GaEventEnv => ({
    domain: window.location.host,
    platform: getPlatform(),
    language: locale,
  });

  const eventUser = (): GaEventUser => {
    if (user) {
      const orderCode = getCookie(CookieKey.orderCode);
      return {
        sso_id: user.profileId,
        code: orderCode || '',
        access: !!orderCode,
        type: user.type,
        ...(user.type === 'ip'
          ? {
              ip: user.ip,
              company: user.name,
            }
          : {}),
      };
    }
    return { code: 'not-logged-in' };
  };

  const eventBodyBase = (): GaEventBodyBase => ({
    title: window.document.title,
    url: window.location.href,
    timestamp: DateTime.utc().toISO(),
    channel: getChannelId(),
  });

  const baseEvent = () => ({
    env: eventEnv(),
    user: eventUser(),
    content: eventBodyBase(),
  });

  const track = (event: GaEventProps) => {
    const base = baseEvent();
    pushToDataLayer({
      event: event.event,
      env: base.env,
      user: base.user,
      content: {
        ...base.content,
        ...event.content,
      },
    });
  };

  return {
    trackPageView: () => track({ event: GaEvent.page_view }),
    trackOpenLead: (content: GaLeadEventBody) =>
      track({
        event: GaEvent.open_lead,
        content,
      }),
    trackGenerateLead: (content: GaLeadEventBody) =>
      track({
        event: GaEvent.generate_lead,
        content,
      }),
  };
};

export default useGaEvents;
